<template>
  <div>

    <Loading v-show="isLoading"/>   

    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-4" v-show="!isLoading">
        <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">ACCESO RESERVAS</div>
        </div>
        <div>
          <form @submit.prevent="iniciarSesion" id="form-login">
            <label for="username" class="block text-900 font-medium mb-2">Usuario</label>
            <InputText type="text" id="username" tabindex="1" v-model="login.username" class="w-full mb-3" autofocus />

            <label for="password" class="block text-900 font-medium mb-2">Clave</label>
            <InputText type="password" id="password" tabindex="2" v-model="login.password" class="w-full mb-3" />

            <div class="flex align-items-center justify-content-between mb-6" style="display:none !important;">
                <div class="flex align-items-center">
                    <Checkbox id="rememberme" :binary="true" v-model="rememberUser" class="mr-2"></Checkbox>
                    <label for="rememberme">Recuerdame</label>
                </div>
                <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Recuperar Clave</a>
            </div>

            <Button type="submit" label="INICIAR SESION" icon="fas fa-user" class="w-full"></Button>
          </form>
        </div>
    </div>  
  </div>
</template>

<script>

import apiService from '../services/apiService.js'

export default {
  data(){
      return {
          login: {
            username: '',
            password: '', 
            device: '', 
          },
          rememberUser: false,
          isLoading: false,
      }
  },
  mounted(){
    this.login.device = this.globalMethods.getDeviceType()
  },
  created() {
    this.toHome()
  },
  methods: {
    async iniciarSesion(){

      this.isLoading = true
      apiService.Auth.login(this.login)
      .then((result) => {
        if(result.status == 'success'){
          this.$store.commit('setToken', result.sesion.access_token)
          this.$store.commit('setAuthenticated', true);
          this.$store.commit('setUserData', result.user);
          this.$store.commit('setNights', result.nights);
          this.$store.commit('setAccessAllow', result.menu);

          this.toHome();

        }else{
          this.FormErrors(result)
          this.isLoading = false
        }
      }).catch((e) => {
        console.log(e)
        this.FormErrors(e)
        this.isLoading = false
      });
    },
    toHome () {
      if (this.$store.state.isAuthenticated) {
        return this.$router.push("/home");
      }
    },
  }
}
</script>